// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/cover.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home {
  text-align: center;
  color: #333;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
}

.mcruffin{
  max-width: 750px;
  position: fixed;
  bottom: 0;
  left: 0;
  transition: 0.25s ease-in-out;
}

.bubble{
  max-width: 500px;
  position: fixed;
  bottom: 600px;
  left: 200px;
}


.mcruffin:hover {
  transform: scale(1.1);
}

.check {
  max-width: 400px;
  position: fixed;
  bottom: 850px;
  left: 235px;
}

.the {
  max-width: 175px;
  position: fixed;
  bottom: 775px;
  left: 235px;
}

.menu {
  max-width: 250px;
  position: fixed;
  bottom: 780px;
  left: 365px;
  animation: mymove 1.5s infinite;
}

@keyframes mymove {
    0% {transform: scale(1);}
    50% {transform: scale(1.3);}
    100% {transform: scale(1);}
}

`, "",{"version":3,"sources":["webpack://./src/components/Home/Home.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,yDAAyC;EACzC,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,SAAS;EACT,OAAO;EACP,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,WAAW;AACb;;;AAGA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,WAAW;EACX,+BAA+B;AACjC;;AAEA;IACI,IAAI,mBAAmB,CAAC;IACxB,KAAK,qBAAqB,CAAC;IAC3B,MAAM,mBAAmB,CAAC;AAC9B","sourcesContent":[".Home {\n  text-align: center;\n  color: #333;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  background-image: url(\"assets/cover.png\");\n  background-size: cover;\n  background-position: center;\n}\n\n.mcruffin{\n  max-width: 750px;\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  transition: 0.25s ease-in-out;\n}\n\n.bubble{\n  max-width: 500px;\n  position: fixed;\n  bottom: 600px;\n  left: 200px;\n}\n\n\n.mcruffin:hover {\n  transform: scale(1.1);\n}\n\n.check {\n  max-width: 400px;\n  position: fixed;\n  bottom: 850px;\n  left: 235px;\n}\n\n.the {\n  max-width: 175px;\n  position: fixed;\n  bottom: 775px;\n  left: 235px;\n}\n\n.menu {\n  max-width: 250px;\n  position: fixed;\n  bottom: 780px;\n  left: 365px;\n  animation: mymove 1.5s infinite;\n}\n\n@keyframes mymove {\n    0% {transform: scale(1);}\n    50% {transform: scale(1.3);}\n    100% {transform: scale(1);}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
