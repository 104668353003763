// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu_img{
    width: 100%;
    height: 600px;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 40px;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/Menu.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,iBAAiB;IACjB,uBAAuB;AAC3B","sourcesContent":[".menu_img{\n    width: 100%;\n    height: 600px;\n    padding-left: 100px;\n    padding-right: 100px;\n    padding-top: 40px;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
